import React, { Suspense, useEffect, useLayoutEffect, useState } from "react"
import "./App.scss"
import { Route, Routes, BrowserRouter, useNavigate } from "react-router-dom"
import zhCN from "antd/lib/locale/zh_CN"
import { ConfigProvider, Skeleton } from "antd"
import { apiResponseCheck, compoundRoutePath } from "./utils"
import { getToken } from "./utils/token"
import { IAdminInfo, fetchLoginAdminInfo } from "./service/Admin"
import { EGlobalKey, setGlobalData } from "./components/GlobalData"
import { IPromiseMenuListItem, IRouter, generateMenus, generateRoutes, setMenus } from "./router"
import LayoutBox from "./views"
import Login from "./views/Login"

const NotFound = React.lazy(() => import("./views/NotFound"))

const Home: React.FC<{ loading?: boolean; tokenKey?: string; routeList: IRouter[] }> = ({ loading, tokenKey, routeList }) => {
	const navigate = useNavigate()

	useLayoutEffect(() => {
		if (routeList.length && !loading) {
			//console.log(location.pathname)
			navigate(routeList[0].path, { replace: true })
		}
	}, [routeList.length])

	useEffect(() => {
		if (!loading && !tokenKey) navigate(compoundRoutePath("/login"), { replace: true })
	}, [loading])

	return null
}

const CheckToken: React.FC<{ loading?: boolean; tokenKey?: string; onChange?(): void }> = ({ loading, tokenKey, onChange }) => {
	const [token] = useState(getToken())
	const navigate = useNavigate()
	useLayoutEffect(() => {
		if (token && token !== tokenKey) {
			onChange?.()
		}
	}, [tokenKey])

	useEffect(() => {
		if (!loading && !getToken()) navigate(compoundRoutePath("/login"), { replace: true })
	}, [loading])

	return !loading && tokenKey ? <NotFound /> : null
}

const App = () => {
	const [menuList, setMenuList] = useState<IPromiseMenuListItem[]>([]),
		[routeList, setRouteList] = useState<IRouter[]>([]),
		[userName, setUserName] = useState<string>(""),
		[tokenKey, setTokenKey] = useState<string>(getToken()),
		[loading, setLoading] = useState(true),
		changeTokenData = (detail: IAdminInfo) => {
			setGlobalData(EGlobalKey.admin, detail)
			setMenus(null)
			setUserName(detail.username)
			setMenuList(generateMenus(detail.menuList || []))
			setRouteList(generateRoutes())
		},
		fetchData = async () => {
			const token = getToken()
			if (token) {
				const detail = await apiResponseCheck(await fetchLoginAdminInfo())
				changeTokenData(detail)
				
			}
			if (token !== tokenKey) setTokenKey(token)
			setLoading(false)
		}

	useLayoutEffect(() => {
		fetchData()
	}, [])

	return (
		<ConfigProvider locale={zhCN}>
			<BrowserRouter>
				<Suspense
					fallback={
						<div>
							<Skeleton active />
							<Skeleton active />
						</div>
					}
				>
					<Routes>
						<Route path={compoundRoutePath("/")} element={<Home loading={loading} tokenKey={tokenKey} routeList={routeList} />} />
						<Route path={compoundRoutePath("/login")} element={<Login onChange={changeTokenData} />} />
						<Route path={compoundRoutePath("/404")} element={<NotFound />} />

						<Route element={<LayoutBox routeList={routeList} menuList={menuList} userName={userName} />}>
							{routeList.map(item => (
								<Route key={item.path} path={item.path} element={React.createElement(item.component)} />
							))}
						</Route>

						<Route path={"*"} element={<CheckToken loading={loading} tokenKey={tokenKey} onChange={fetchData} />} />

						{/* {routeList.map(item => (
							<Route
								key={item.path}
								path={item.path}
								element={
									<LayoutBox menuList={menuList} userName={userName}>
										{React.createElement(item.component)}
									</LayoutBox>
								}
							/>
						))} */}
					</Routes>
				</Suspense>
			</BrowserRouter>
		</ConfigProvider>
	)
}

export default App
