import React, {useState} from "react";
import {Form, Input, message, Modal} from "antd";
import {apiResponseCheck} from "../utils";
import {PasswordRegCheck} from "../utils/validate";
import {updateLoginAdminPassword} from "../service/Admin";
import {removeLoading, addLoading} from "./Loading";
import {IReactRef} from "../types";

interface Props {
    onChange?(): void,

    innerRef?: IReactRef<{ setData?(): void }>
}

const EditAdminPwdModal: React.FC<Props> = ({onChange, innerRef}) => {
    const [visible, setVisible] = useState(false),
        [formElem] = Form.useForm(),
        initData = {
            oldPassword: '',
            newPassword: '',
            confirmPassword: ''
        },

        handleConfirmPwd = (value: string) => {
            const pwd = formElem?.getFieldValue('newPassword')
            if (pwd === value) {
                return Promise.resolve()
            } else {
                return Promise.reject(new Error('两次密码输入不一致'))
            }
        },

        setData = () => {
            formElem?.resetFields()
            setVisible(true)
        },

        onCancel = () => {
            setVisible(false)
        },

        onSubmit = () => {
            formElem?.validateFields().then(values => {
                addLoading()
                updateLoginAdminPassword(values).then(res => {
                    removeLoading()
                    apiResponseCheck(res).then(() => {
                        message.success('修改成功')
                        onCancel()
                        onChange?.()
                    })
                })
            })
        }

    innerRef && (innerRef.current = {setData})

    return <Modal title={'修改密码'} width={400} open={visible} onOk={onSubmit}
                  onCancel={onCancel}>
        <Form labelCol={{span: 6}} initialValues={initData} form={formElem}>
            <Form.Item label={'旧密码'} name={'oldPassword'} required
                       rules={[{validator: (_, value) => PasswordRegCheck(value)}]}>
                <Input type={"password"} maxLength={20} placeholder={'请输入旧密码'}/>
            </Form.Item>

            <Form.Item label={'新密码'} name={'newPassword'} required
                       rules={[{validator: (_, value) => PasswordRegCheck(value)}]}>
                <Input type={"password"} maxLength={20} placeholder={'请输入新密码'}/>
            </Form.Item>

            <Form.Item label={'确认密码'} name={'confirmPassword'} required
                       rules={[{validator: (_, value) => handleConfirmPwd(value)}]}>
                <Input type={"password"} maxLength={20} placeholder={'请再次输入密码'}/>
            </Form.Item>
        </Form>
    </Modal>
}

export default EditAdminPwdModal
