import { ISelectOption } from "../types";

// 状态->0待提交第三方1提交第三方中2已提交第三方3完成
enum ESupplierRefundState {
    WAIT_SUBMIT = 0,
    SUBMITTING = 1,
    SUBMITTED = 2,
    FINISHED = 3
}


export const SupplierRefundStateOptions: ISelectOption<ESupplierRefundState>[] = [
    {
        value: ESupplierRefundState.WAIT_SUBMIT,
        label: '待提交第三方平台'
    },
    {
        value: ESupplierRefundState.SUBMITTING,
        label: '提交第三方平台中'
    },
    {
        value: ESupplierRefundState.SUBMITTED,
        label: '已提交第三方平台'
    },
    {
        value: ESupplierRefundState.FINISHED,
        label: '完成'
    }
]


export default ESupplierRefundState;