import React, { Suspense, useEffect, useRef, useState } from "react";
import {
	Badge,
	Button,
	Image,
	Layout,
	Menu,
	MenuProps,
	Modal,
	Skeleton,
	Space,
	Tooltip,
	Typography,
} from "antd";
import { Outlet, useNavigate } from "react-router-dom";
import { getToken, removeToken } from "../utils/token";
import HomeBreadcrumb from "../components/HomeBreadcrumb";
import { IPromiseMenuListItem, IRouter } from "../router";
import { apiResponseCheck, compoundRoutePath, ONE_MINUTE } from "../utils";
import { addLoading, removeLoading } from "../components/Loading";
import { logout } from "../service/Login";
import { setNavigate } from "../components/GlobalData";
import { MenuUnfoldOutlined, MenuFoldOutlined } from "@ant-design/icons";
import Logo from "../assets/logo.svg";
import { FormOutlined } from "@ant-design/icons";
import EditAdminPwdModal from "../components/EditAdminPwdModal";
import { css } from "@emotion/react";
import { fetchPayExceptionCount, fetchSupplierExceptionCount } from "../service/AfterSale";
import { fetchJKOrderExceptionCount, fetchOrderExceptionCount } from "../service/Order";

type MenuItem = Required<MenuProps>["items"][number];

const trigger = css`
  padding: 0 24px;
  font-size: 18px;
  line-height: 64px;
  cursor: pointer;
  transition: color 0.3s;

  &:hover {
    color: #1890ff;
  }
`;

const getItem = (
	data: {
		count?: number,
		label: React.ReactNode,
		key: React.Key,
		icon?: React.ReactNode,
		children?: MenuItem[],
		type?: "group"
	},
) => {

	return {
		...data,
		label: data.count ? <>
			{data.label}
			<Badge count={data.count} size="small" offset={[5, -16]} />
		</> : data.label
	} as MenuItem;
}

const Main: React.FC<{
	menuList: IPromiseMenuListItem[];
	userName: string;
	routeList: IRouter[]
}> = ({ routeList, menuList, userName }) => {
	const navigate = useNavigate(),
		[countList, setCountList] = useState<{ path: string, count: number }[]>([]),
		[collapsed, setCollapsed] = useState<boolean>(false),
		editAdminPwdModal = useRef<{ setData?(): void }>(),

		supplierException = routeList.find(item => item.path === compoundRoutePath("/supplierexceptionlist")),
		customerException = routeList.find(item => item.path === compoundRoutePath("/customerexceptionlist")),
		failureOrderException = routeList.find(item => item.path === compoundRoutePath("/failureorder")),
		failureDemandException = routeList.find(item => item.path === compoundRoutePath("/failuredemand")),


		fetchCount = async () => {

			const newCountList = []
			if (supplierException) {
				const count = await apiResponseCheck(await fetchSupplierExceptionCount());
				newCountList.push({ path: supplierException.path, count })
			}
			if (customerException) {
				const count = await apiResponseCheck(await fetchPayExceptionCount())
				newCountList.push({ path: customerException.path, count })
			}

			if (failureOrderException) {
				const count = await apiResponseCheck(await fetchOrderExceptionCount())
				newCountList.push({ path: failureOrderException.path, count })
			}

			if (failureDemandException) {
				const count = await apiResponseCheck(await fetchJKOrderExceptionCount())
				newCountList.push({ path: failureDemandException.path, count })
			}
			
			if (newCountList.length !== countList.length || newCountList.some(item => !countList.some(item2 => item.path === item2.path) || countList.some(item2 => item.path === item2.path && item.count !== item2.count))) {
				setCountList(newCountList)
			}

		},

		onLogout = () => {
			Modal.confirm({
				type: "warning",
				title: "提示",
				content: "是否确定退出",
				onOk: async () => {
					addLoading();

					try {
						await apiResponseCheck(await logout());
						navigate(compoundRoutePath("/login"), { replace: true });
					} finally {
						removeToken();
						removeLoading();
					}
				},
			});
		},
		onToggleCollapsed = () => {
			setCollapsed(!collapsed);
		},
		onEditPwd = () => {
			editAdminPwdModal?.current?.setData?.();
		},
		onEditPwdChange = () => {
			removeToken();
			navigate(compoundRoutePath("/login"), { replace: true });
		},
		onMenuClick = (item: MenuItem) => {
			item && item?.key && navigate(compoundRoutePath(`${item?.key}`));
		};

	useEffect(() => {
		setNavigate(navigate);
		const token = getToken();
		if (!token) {
			navigate(compoundRoutePath("/login"), { replace: true });
		} else {
			fetchCount()
			const id = setInterval(fetchCount, ONE_MINUTE);
			return () => clearInterval(id);
		}
	}, []);

	return menuList.length ? (
		<>
			<Layout style={{ minHeight: "100%" }} hasSider>
				<Layout.Sider
					trigger={null}
					collapsed={collapsed}
					style={{
						height: "100vh",
						position: "sticky",
						left: 0,
						top: 0,
						bottom: 0,
					}}
				>
					<div
						css={css`
              display: flex;
              align-items: center;
              justify-content: center;
              padding: 10px 0;
            `}
					>
						<Image height={44} preview={false} src={Logo} />
						{!collapsed && (
							<Typography.Text
								style={{ fontSize: 18, color: "#fff", marginLeft: 15 }}
							>
								宠物商城
							</Typography.Text>
						)}
					</div>
					<div
						css={css`
              overflow-y: auto;
              height: calc(100vh - 65px);
              &::-webkit-scrollbar {
                display: none;
              }
            `}
					>
						<Menu
							theme="dark"
							mode="inline"
							onClick={onMenuClick}
							items={menuList.map(
								(menu, index) =>
									menu.path
										? getItem({ label: menu.title, key: menu.path, icon: menu.icon, count: countList.find(item => item.path === menu.path)?.count })
										: getItem(
											{
												label: menu.title, key: `${menu.title}_${index}`, icon: menu.icon, children:
													menu.routes.filter(item => !!item.path).map(item => getItem({ label: item.title, key: item.path, count: countList.find(countItem => item.path === countItem.path)?.count }))
											}
										)
							)}
						/>
					</div>
				</Layout.Sider>

				<Layout>
					<Layout.Header
						css={css`
              display: flex;
              align-items: center;
              background-color: #fff;
              padding-left: 0px;
            `}
						style={{ backgroundColor: "#fff", paddingLeft: 0 }}
					>
						{collapsed ? (
							<MenuUnfoldOutlined css={trigger} onClick={onToggleCollapsed} />
						) : (
							<MenuFoldOutlined css={trigger} onClick={onToggleCollapsed} />
						)}
						{window.location.host.indexOf("pettest.") >= 0 && (
							<Typography.Text>测试环境</Typography.Text>
						)}
						<div
							css={css`
                flex: 1;
                text-align: right;
              `}
						>
							<Space>
								<span>当前用户：{userName}</span>
								<Button type={"link"} onClick={onLogout}>
									退出登陆
								</Button>

								<Tooltip title={"修改密码"}>
									<Button
										type={"link"}
										icon={<FormOutlined />}
										onClick={onEditPwd}
									/>
								</Tooltip>
							</Space>
						</div>
					</Layout.Header>
					<Layout style={{ padding: "0 24px 24px" }}>
						<Layout.Content>
							<HomeBreadcrumb />
							<div style={{ minHeight: 360 }}>
								<Suspense
									fallback={
										<div>
											<Skeleton active />
											<Skeleton active />
										</div>
									}
								>
									<Outlet />
								</Suspense>
							</div>
						</Layout.Content>
					</Layout>
				</Layout>
			</Layout>

			<EditAdminPwdModal
				innerRef={editAdminPwdModal}
				onChange={onEditPwdChange}
			/>
		</>
	) : null;
};

export default Main;
