import { FormInstance } from "antd"

export type ISetFormFun = ((from: FormInstance<any>, id: string | number,index:number) => void) | null

/*分页数据*/
export interface IListData<T = any> {
	pageNum: number
	pageSize: number
	totalPage: number

	total: number

	list: T[]
}

export interface IListSate<T = any> {
	listData: IListData<T>
	loading: boolean
	selectedRowKeys?: any[]
}

export interface ISelectOption<S = any> {
	value: S
	label: string
}

export enum EPlaceHolder {
	Input = "请输入",
	Select = "请选择",
}

export enum ELogisticUnit {
	kg = "Kg",
	m3 = "m³",
	count = "件",
}

/*image/gif, image/png, image/jpeg, image/bmp, image/webp, image/x-icon, image/vnd.microsoft.icon*/
export enum EImageMIME {
	gif = "image/gif",
	png = "image/png",
	xpng = "image/x-png",
	jpeg = "image/jpeg",
	pjpeg = "image/pjpeg",
	bmp = "image/bmp",
	webp = "image/webp",
	xIcon = "image/x-icon",
	msIcon = "image/vnd.microsoft.icon",
}

export enum EFileMIME {
	excel = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel,.csv",
	doc = ".doc,.docx,.xml,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document",
	pdf = ".pdf,application/pdf",
}

/*
* .objectFitCover {
    object-fit: cover;
}

.objectFitFill {
    object-fit: fill;
}

.objectFitContain {
    object-fit: contain;
}
* */
export enum EImageFitClass {
	cover = "objectFitCover",
	fill = "objectFitFill",
	contain = "objectFitContain",
}

export enum EStatusName {
	disable = "停用",
	enable = "激活",
}

export enum EStyleClass {
	AlignCenter = "align-center",
	AlignLeft = "align-left",
	AlignRight = "align-right",
	FlexBox = "flexBox",
	FlexBoxStart = "flexBoxStart",
	FlexBoxBaseline = "flexBoxBaseline",
	AutoFlex = "autoFlex",
	ClearFlex = "clearFlex",
}

export type IModalProps = {
	visible?: boolean
	onCancel?(data: false): void
}

export type IReactRef<S = any> = {
	current?: S
}
