import React from "react"
import ReactDOM, { Root } from "react-dom/client"
import Styles from "./Loading.module.scss"
import { generateClassName } from "../../utils"

interface Props extends React.HTMLAttributes<HTMLDivElement> {
	txt?: string
	loading?: boolean | number
	full?: boolean | number
}

export default class Loading extends React.Component<Props> {
	render() {
		const { txt, children, loading, full, className, ...attr } = this.props

		return (
			<div {...attr} className={generateClassName(className, { [Styles.loading]: loading && !full })}>
				{loading ? (
					<div className={generateClassName(Styles.loadingMask, { [Styles.full]: full })}>
						<div className={Styles.spinner}>
							<svg viewBox="25 25 50 50" className={Styles.circular}>
								<circle cx="50" cy="50" r="20" fill="none" className={Styles.path} />
							</svg>
							{txt ? <div className={Styles.text}>{txt}</div> : null}
						</div>
					</div>
				) : null}
				{children}
			</div>
		)
	}
}

let elem: HTMLDivElement | null = null
let root: Root | null = null

export const addLoading = () => {
	removeLoading()

	elem = document.createElement("div")
	root = ReactDOM.createRoot(elem)
	root.render(<Loading full={1} loading={1} />)
	document.body.append(elem)
}

export const removeLoading = () => {
	if (root) {
		root.unmount()
		root = null
	}
	if (elem) {
		elem.remove()
		elem = null
	}
}
