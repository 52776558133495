import {requestPost} from "../utils/request";

/*登陆*/
export const login = (username: string, password: string) => requestPost<string>('/admin/login', {
    username,
    password
})


/*退出登陆*/
export const logout = () => requestPost('/admin/logout')
