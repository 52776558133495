import { EStatusName, ISelectOption } from "../types"
import { DefaultOption } from "../utils"
import { DataNode } from "rc-tree-select/lib/interface"

export const PageSizeOptions = ["20", "50", "100", "500", "1000"]

export enum EStatus {
	enable = 1,
	disable = 0,
}

export const StatusOptions: ISelectOption[] = [DefaultOption, { value: 1, label: EStatusName.enable }, { value: 0, label: EStatusName.disable }]

export const getToggleStatus = (value: EStatus) => (value === EStatus.disable ? EStatus.enable : EStatus.disable)

export interface IDataNode extends DataNode {
	parentId?: string|number | null
}

export const generateTreeData = (list: IDataNode[], parentId: string|number | null = null): undefined | DataNode[] => {
	const [filter, removeLf] = list.reduce(
		(list: [IDataNode[], IDataNode[]], item) => {
			list[!item.parentId || item.parentId === parentId ? 0 : 1].push(item)
			return list
		},
		[[], []]
	)

	//console.log(filter, removeLf)

	const newList = filter.map(item => {
		//const children = list.filter(sub => sub.parentId === item.viewCategoryId)
		return {
			value: item.value,
			title: item.title,
			key: item.value,
			children: generateTreeData(removeLf, item.value),
		}
	})
	return newList.length ? newList : undefined
}
