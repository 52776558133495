import dayjs, { Dayjs } from "dayjs"
import { message } from "antd"
import React from "react"
import { IListData, IListSate, ISelectOption } from "../types"
import smartJson from "./smartJson"

export const DefaultOption: ISelectOption = { value: "", label: "全部" }

export const getDecimal = (num: number, fixedNum: number = 2) => {
	const _num = num.toFixed(fixedNum)
	return _num.split(".")[1]
}

export const getInteger = (num: number) => Math.floor(num)

export const getFixed = (num: number = 0, fixedNum: number = 2) => num.toFixed(fixedNum)

export const fillZero = (n: number = 0) => (n < 10 ? "0" + n : "" + n)

export const amountLocal = (amount: number = 0, fixed: number = 2) => {
	amount = amount && isNumber(amount) ? amount : 0
	const str = amount.toFixed(fixed).split(".")
	return `${Number(str[0]).toLocaleString()}${str[1] ? "." : ""}${str[1] || ""}`
}

export const getAnimationFrame = () =>
	(window && window.requestAnimationFrame) ||
	function (callback: any) {
		setTimeout(callback, 1000 / 60)
	}

export const clearAnimationFrame = (id: any) => {
	//@ts-ignore
	const api = (window && (window.cancelAnimationFrame || window.webkitCancelAnimationFrame)) || clearTimeout
	api(id)
}

export const anchorBlank = (href: string, download?: string) => {
	const a = document.createElement("a")
	//a.target= '_blank'
	a.href = href
	download && (a.download = download)

	document.body.appendChild(a)
	a.click()
	a.remove()
}

export const hackNumber = (num: number) => Math.round(num * 100) / 100

export const getShopName = (): string => {
	let shopName = window ? window.location.href.split("/").find(str => /^sn-[\w\d]+$/i.test(str)) : ""
	if (shopName) {
		shopName = shopName.replace(/^sn-/i, "")
	} else {
		shopName = window ? window.location.host.split(".")[0] : ""
	}
	return shopName
}

export const isThisDomain = (url: string): boolean => {
	const domain = window ? window.location.host : ""
	if (/^http/i.test(url)) {
		return url.indexOf(domain) >= 0
	}
	return true
}

export const isUndefined = (data: any): data is undefined => typeof data === "undefined"
export const isObject = <T = Object>(data: any): data is T => typeof data === "object" && typeof data !== "bigint" && data !== null
export const isEmpty = (data: any): data is null | undefined | '' => isUndefined(data) || data === null || (isStr(data) ? data.trim() === "" : false)

export const isStr = (data: any): data is string => typeof data === "string"

export const isNumber = (data: any): data is number => typeof data === "number"
export const isFunction = (data: any): data is Function => typeof data === "function"

const filterClassName = (data: any): string => {
	if (isEmpty(data)) {
		return ""
	} else if (isObject(data)) {
		return Object.keys(data)
			.filter(key => !!data[key])
			.map(key => {
				if (isEmpty(data[key]) || typeof data[key] === "boolean" || typeof data[key] === "number") {
					return data[key] ? key : ""
				}
				return filterClassName(data[key])
			})
			.filter(v => !!v)
			.join(" ")
	} else if (isFunction(data)) {
		return filterClassName(data())
	} else if (isStr(data) || isNumber(data)) {
		return `${data}`
	} else {
		return ""
	}
}

export const generateClassName = (...attr: Array<any>): string | undefined => {
	const classNameList: string[] = attr
		.reduce((list: string[], item) => {
			const res = Array.isArray(item) ? generateClassName(...item) : filterClassName(item)
			res && list.push(res)
			return list
		}, [])
		.filter((v: string) => !!v)
	return classNameList && classNameList.length ? classNameList.join(" ") : undefined
}

export const GCNAlias = generateClassName

/*
export const trackPageview = (pageURL:string = window.location.href) => {
	window._hmt && window._hmt.push(['_trackPageview', pageURL]);
}*/

export function windowWidth() {
	return window ? (window.screen.width > 0 ? (window.innerWidth >= window.screen.width || window.innerWidth === 0 ? window.screen.width : window.innerWidth) : window.innerWidth) : 0
}

export function windowHeight() {
	return window ? (window.screen.height > 0 ? (window.innerHeight >= window.screen.height || window.innerHeight === 0 ? window.screen.height : window.innerHeight) : window.innerHeight) : 0
}

export const joinStr = (list: Array<string | number>) => list.join("★")

export const splitStr = (str: string) => str.split("★")

export const getMoment = (list: string | null) => {
	return dayjs(list)
}

export const getMomentList = (list: [string | null | undefined, string | null | undefined]): [Dayjs | null, Dayjs | null] => {
	return list.map(item => (!item ? null : getMoment(item))) as [Dayjs | null, Dayjs | null]
}

export const getMomentToStr = (data: Dayjs, format: string = "YYYY-MM-DD") => data && data.format(format)

//YYYY-MM-DD HH:mm:ss
export const getMomentListToStr = (data: Dayjs[], format: string = "YYYY-MM-DD") => data.map(item => getMomentToStr(item, format))

export const formatDateToDateTime = (list: string[]) => list.map((date, index) => `${date} ${index ? "23:59:59" : "00:00:00"}`)

type IApiCheckOptions<T = any> = {
	checkNull?: boolean
	showErr?: boolean
	errMsg?: string | ((data: { code: number; data: T; message: string }) => string)
}

export const apiResponseCheck = <T = any>(data: { code: number; data: T; message: string }, options: boolean | IApiCheckOptions<T> = false, _showErr = true): Promise<T> =>
	new Promise((resolve, reject) => {
		const { checkNull = false, showErr = true, errMsg } = isObject<IApiCheckOptions<T>>(options) ? options : { checkNull: options, showErr: _showErr, errMsg: "" }

		//console.log(data, data.code === 10000)
		if (data.code === 0) {
			if (checkNull && data.data === null) {
				reject(data)
			} else {
				resolve(data.data)
			}
			return true
		}
		showErr && message.error(isFunction(errMsg) ? errMsg(data) : errMsg || data.message)
		reject(data)
		return false
	})

export type IReactInputAttribute = React.ChangeEvent<HTMLInputElement>
export const isReactInputElement = (value: any): value is IReactInputAttribute => isObject(value) && !!value.target

export const getEmptyListData = <T = any>(pageSize = 20): IListData<T> => ({
	pageNum: 1,
	totalPage: 0,
	pageSize,
	total: 0,
	list: [],
})

export const filterObjNull = <T = any>(data: T, instead?: any): T => {
	const obj: any = {}
	Object.keys(data as any).forEach(k => {
		if (isEmpty(data[k as keyof T])) {
			isUndefined(instead) || (obj[k] = instead)
		} else {
			obj[k] = data[k as keyof T]
		}
	})
	return obj
}

export const generateLabelCol = (index: number = 0) => ({
	xs: 6 + index,
	sm: 6 + index,
	md: 4 + index,
	lg: 3 + index,
	xl: 2 + index,
	xxl: 2 + index,
})

/*跨分页选取用*/
export const generateSupplementaryKeys = (allSelected: string[], listKey: string[], select: string[] = []) => {
	return [...allSelected.filter(k => !listKey.some(v => v === k)), ...select]
}

export const compoundRoutePath = (path: string) => {
	const dir = (process.env.PUBLIC_URL || "").replace(/\//g, "")
	const _path = path.toLowerCase().trim()

	path = path.trim()

	if (!dir) {
		return _path[0] === "/" ? path : `/${path}`
	} else if (_path.replace(/\//g, "") === dir) {
		return `/${dir}/`
	} else if (_path.indexOf(`/${dir}/`) === 0) {
		return path
	} else if (_path.indexOf(`${dir}/`) === 0) {
		return `/${path}`
	}
	return `/${dir}${path[0] === "/" ? "" : "/"}${path}`
}

export const getListIndex = (listData: IListData, index: number) => ((listData.pageNum || 1) - 1) * listData.pageSize + index + 1

export const getEmptyListDataState = <T = any>(): IListSate<T> => ({
	loading: true,
	listData: getEmptyListData(),
})

export const aroundAmount = (amount: number, p = 100) => Math.round(amount * p) / p

export const CharColorList = [
	"rgba(91, 143, 249, 0.85)",
	"rgba(102, 182, 252, 0.85)",
	"rgba(115, 223, 254, 0.85)",
	"rgba(155, 234, 249, 0.85)",
	"rgba(195, 245, 244, 0.85)",
	"rgba(236, 255, 240, 0.85)",
	"rgba(240, 252, 196, 0.85)",
	"rgba(243, 249, 152, 0.85)",
	"rgba(246, 245, 109, 0.85)",
	"rgba(249, 242, 65, 0.85)",
	"rgba(252, 238, 22, 0.85)",
	"rgba(237, 207, 14, 0.85)",
	"rgba(221, 176, 7, 0.85)",
	"rgba(206, 148, 0, 0.85)",
	"rgba(191, 119, 0, 0.85)",
	"rgba(175, 91, 0, 0.85)",
	"rgba(149, 78, 0, 0.85)",
	"rgba(122, 66, 0, 0.85)",
	"rgba(96, 53, 0, 0.85)",
	"rgba(70, 41, 0, 0.85)",
	"rgba(59, 64, 19, 0.85)",
	"rgba(48, 87, 38, 0.85)",
	"rgba(38, 111, 57, 0.85)",
	"rgba(27, 134, 76, 0.85)",
	"rgba(16, 158, 95, 0.85)",
	"rgba(4, 181, 115, 0.85)",
	"rgba(0, 203, 134, 0.85)",
	"rgba(0, 226, 154, 0.85)",
	"rgba(0, 248, 173, 0.85)",
	"rgba(0, 255, 184, 0.85)",
	"rgba(0, 255, 208, 0.85)",
]

export const getImageList = (list: string | string[]): string[] => (Array.isArray(list) ? list : smartJson(list)) || []

type ITryCatchProps = {
	final?: Function
	error?: (e: any) => void
}

export const tryCatch = async (f: Function, s?: Function | ITryCatchProps) => {
	const isFunc = isFunction(s),
		isObj = isObject<ITryCatchProps>(s)
	try {
		await f()
	} catch (e) {
		console.error(e)
		isObj && s.error && s.error(e)
	} finally {
		s && isFunc && s()
		isObj && s.final && s.final()
	}
}

/*JSON展示*/
export function syntaxHighlight(json: any) {
	/*
	.string { color: green; }
.number { color: darkorange; }
.boolean { color: blue; }
.null { color: magenta; }
.key { color: red; }
	* */

	if (typeof json != "string") {
		json = JSON.stringify(json, undefined, 2)
	}
	json = json.replace(/&/g, "&amp;").replace(/</g, "&lt;").replace(/>/g, "&gt;")
	return json.replace(/("(\\u[a-zA-Z0-9]{4}|\\[^u]|[^\\"])*"(\s*:)?|\b(true|false|null)\b|-?\d+(?:\.\d*)?(?:[eE][+\-]?\d+)?)/g, (match: any) => {
		let cls = "number"
		let style = "color: darkorange;"
		if (/^"/.test(match)) {
			if (/:$/.test(match)) {
				cls = "key"
				style = "color: red;"
			} else {
				cls = "string"
				style = "color: green;"
			}
		} else if (/true|false/.test(match)) {
			cls = "boolean"
			style = "color: blue;"
		} else if (/null/.test(match)) {
			cls = "null"
			style = "color: magenta;"
		}
		return '<span class="' + cls + '" style="' + style + '">' + match + "</span>"
	})
}

export const getSelectOptionLabel = <S = any>(options: ISelectOption<S>[], value?: S) => options.find(item => item.value === value)?.label ?? "未知状态"

/*  */
export const getChineseMoney = (amount: number) => {
	if (!amount) return "零"
	let moneyArr = Number(Math.abs(amount)).toFixed(2).split(".") // 转成字符串；分割整数和小数
	const money = moneyArr[0]
		?.replace(/\b(0+)/g, "")
		.split("")
		.reverse() //整数部分： 去掉头部的0, 000130130 => 130130；传成数组；倒置
	const digit = moneyArr[1]?.replace(/(0+)\b/g, "").split("") //小数部分： 去掉后面的0, 20 => 2；传成数组；

	const dic = ["零", "壹", "贰", "叁", "肆", "伍", "陆", "柒", "捌", "玖"]

	const dicUnit = ["", "拾", "佰", "仟"]

	const digitDicUnit = ["角", "分"]

	// 处理仟、佰、拾； ['1', '0', '0', '1'] => 叁仟零零壹
	const getInfo = (item: string[]) => {
		if (!item || item.length > 4) return ""

		return item
			.map((subItem, index) => {
				const item = Number(subItem)
				if (item === 0) {
					return dic[0]
				}
				return `${dic[item]}${dicUnit[index]}`
			})
			.reverse()
			.join("")
			.replace(/(零+)$/g, "") //反转回来；拼接成字符串；去掉尾部的 零
	}

	let resMoney: string | number = 0

	// 处理整数部分
	switch (true) {
		case money.length < 5:
			resMoney = getInfo(money)
			break
		case money.length < 9:
			resMoney = `${getInfo(money.slice(4, money.length))}万${getInfo(money.slice(0, 4))}`
			break
		case money.length < 13:
			resMoney = `${getInfo(money.slice(8, money.length))}亿${getInfo(money.slice(4, 8))}万${getInfo(money.slice(0, 4))}`
			break
	}

	resMoney = `${resMoney
		.toString()
		.replace(/零零零/g, "零")
		.replace(/零零/g, "零")}`

	let digitMoney
	// 处理小数部分
	if (digit) {
		digitMoney = digit
			.map((digitItem, index) => {
				const digit = Number(digitItem)
				if (digit === 0) {
					return ""
				}
				return `${dic[digit]}${digitDicUnit[index]}`
			})
			.join("")
	}

	!!digitMoney ? (resMoney = `${resMoney ? `${resMoney}元` : resMoney}${digitMoney}`) : (resMoney = `${resMoney}元整`)

	return `${amount < 0 ? "负" : ""}${resMoney}`
}


export const ONE_DAY = 864e5;
export const ONE_HOUR = 36e5;
export const ONE_MINUTE = 6e4;