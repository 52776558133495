const TOKEN_NAME = 'PET_ADMIN_TOKEN';

export const setToken = (token: string) => {
    localStorage.setItem(TOKEN_NAME, JSON.stringify({token, timestamp: new Date().getTime()}))
}

export const getToken = () => {
    const tokenStr = localStorage.getItem(TOKEN_NAME)
    let res = ''
    if (tokenStr) {
        try {
            res = JSON.parse(tokenStr).token
        } catch {

        }
    }
    return res;
}

export const removeToken = () => {
    localStorage.removeItem("menu")
    localStorage.removeItem(TOKEN_NAME)
}
