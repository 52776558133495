import { RcFile } from "antd/es/upload";
import EAfterSaleType from "../Enums/EAfterSaleType";
import EReturnType from "../Enums/EReturnType";
import IOmsAfterSale from "../conts/IOmsAfterSale";
import IOmsAfterSaleDetail from "../conts/IOmsAfterSaleDetail";
import IOmsAfterSaleEditDTO from "../conts/IOmsAfterSaleEditDTO";
import IOmsAfterSaleListDTO from "../conts/IOmsAfterSaleListDTO";
import IOmsAfterSaleOperateLog from "../conts/IOmsAfterSaleOperateLog";
import ISysLogisticCompany from "../conts/ISysLogisticCompany";
import { IListData, ISelectOption } from "../types";
import { ONE_HOUR, ONE_MINUTE, apiResponseCheck, isEmpty } from "../utils";
import { IAjaxPage, requestFomData, requestFomUrlencoded, requestPost, requestThrottle } from "../utils/request";
import IOmsPayListDTO from "../conts/IOmsPayListDTO";
import IOmsPay, { IOmsPayInfoVO } from "../conts/IOmsPay";
import ISupplierRefundInfoVO from "../conts/ISupplierRefundInfoVO";
import IOmsSupplierRefundListDTO from "../conts/IOmsSupplierRefundListDTO";
import IOmsSupplierRefund from "../conts/IOmsSupplierRefund";
import ISysPayTradeFlow from "../conts/ISysPayTradeFlow";
import ESupplierRefundState from "../Enums/ESupplierRefundState";

const getUrl = (url = '') => `/aftersale${url}`

/* 保存草稿 */
export const saveAfterSale = (data: IOmsAfterSaleEditDTO) => requestPost<number>(getUrl("/save"), data)

/* 提交售后 */
export const submitAfterSale = (data: IOmsAfterSaleEditDTO) => requestPost(getUrl("/submit"), data)

/* 关闭售后 */
export const closeAfterSale = (afterSaleId: number) => requestPost(getUrl("/close"), { afterSaleId })

/* 审核通过 */
export const resolveAfterSale = (data: { afterSaleId: number, note: string }) => requestPost(getUrl("/approveSucess"), data)


/* 审核失败 */
export const rejectAfterSale = (data: { afterSaleId: number, note: string }) => requestPost(getUrl("/approveFail"), data)


/* 售后单查询 */
export const fetchAfterSaleList = (data: IAjaxPage & IOmsAfterSaleListDTO) => requestPost<IListData<IOmsAfterSale>>(getUrl("/list"), data)

/* 售后单详情 */
export const fetchAfterSaleDetail = (afterSaleId: number) => requestFomUrlencoded<IOmsAfterSale>(getUrl("/getInfo"), { afterSaleId })

/* 操作日志 */
export const fetchAfterSaleDetailOperateLog = (data: { afterSaleId: number, subId?: number }) => requestPost<IOmsAfterSaleOperateLog[]>(getUrl("/listOperateLog"), data)

/* 明细数据 */
export const fetchAfterSaleDetailList = (data: { afterSaleId: number, note?: string }) => requestPost<IOmsAfterSaleDetail[]>(getUrl("/listAfterSaleDetail"), data)

/* 上传图片到阿里巴巴 */
export const uploadFileToAlibaba = (file: File | RcFile) => requestFomData<string>(getUrl("/1688/uploadRefundVoucher"), { file })

/* 获去1688售后原因 */
const _fetchAlibabaReasonList = (orderId: number) => requestFomUrlencoded<string[]>(getUrl("/list1688RefundReason"), { orderId })

/* 获取物流公司 */
const _fetchAlibabaLogisticCompany = (keyword?: string) => requestPost<ISysLogisticCompany[]>(getUrl("/listLogisticCompany"), { keyword })





export const [fetchCacheAlibabaLogisticCompany, fetchAlibabaLogisticCompany] = requestThrottle({
    api: _fetchAlibabaLogisticCompany,
    times: ONE_HOUR,
    saveKey: 'alibabaLogisticCompany',
})

export const [fetchCacheAlibabaReasonList, fetchAlibabaReasonList] = requestThrottle({
    api: _fetchAlibabaReasonList,
    times: ONE_HOUR,
    saveKey: 'alibabaReasonList',
})


export const generateReasonList = async ({ afterSaleType, returnType, isAlibaba, noNeedSupplierRefund, orderId }: { afterSaleType?: EAfterSaleType, returnType: EReturnType, isAlibaba?: boolean, noNeedSupplierRefund?: boolean, orderId: number }): Promise<ISelectOption[]> => {

    if (afterSaleType === EAfterSaleType.return) {
        if (isAlibaba) {
            // TODO 通过接口查询
            const data = await apiResponseCheck(await fetchAlibabaReasonList(orderId))
            return data.map(item => ({ value: item, label: item }))

        } else {
            switch (returnType) {
                case EReturnType.not:
                    return [
                        { value: '未收到商品', label: '未收到商品' },
                        { value: '货品破损', label: '货品破损' },
                    ]
                case EReturnType.normal:
                    return [
                        { value: "货品破损", label: '货品破损' },
                        { value: "产生不良反应", label: '产生不良反应' },
                        { value: "药品无效", label: '药品无效' },
                        { value: "客户自身原因不想要了", label: '客户自身原因不想要了' },
                        { value: "商品与描述不符", label: '商品与描述不符' },
                        { value: "商品效期不满意", label: '商品效期不满意' },
                        { value: "包装与实物不符", label: '包装与实物不符' },
                    ]
                default:
                    return [
                        { value: '货品破损', label: '货品破损' },
                        { value: "客户自身原因不想要了", label: '客户自身原因不想要了' },
                    ]
            }
        }
    } else if (afterSaleType === EAfterSaleType.resend) {

        /* 需要供应商退款 */

        if (noNeedSupplierRefund) {

            return [
                { value: "货品破损", label: '货品破损' },
                { value: "未及时收货,快递退回", label: '未及时收货,快递退回' },
            ]
        } else if (isAlibaba) {
            // TODO 通过接口查询
            const data = await apiResponseCheck(await fetchAlibabaReasonList(orderId))
            return data.map(item => ({ value: item, label: item }))
        } else {
            return [
                { value: "货品破损", label: '货品破损' },
                { value: "未及时收货,快递退回", label: '未及时收货,快递退回' },
            ]
        }
    }

    return []
}

/* 打款-列表 */
export const fetchAfterPayList = (data: IAjaxPage & IOmsPayListDTO) => requestPost<IListData<IOmsPay>>(getUrl("/listOmsPay"), data)


/* 打款-标记需要补充信息 */
export const signNeedProcess = (data: { payId: number, needProcess: boolean, note?: string }) => requestPost(getUrl("/signNeedProcess"), data)

/* 打款-客服补充信息 */
export const addPayInfo = (data: { payId: number, payInfo: IOmsPayInfoVO }) => requestPost(getUrl("/addPayInfo"), data)

/* 打款 */
export const returnPay = (data: { payId: number, payExtraAmount: number, note?: string }) => requestPost(getUrl("/pay"), data)

/* 供应商售后当前状态 */
export const fetchSupplierAfterSaleInfo = (refundId: number) => requestPost<ISupplierRefundInfoVO>(getUrl("/getSupplierAfterSaleInfo"), { refundId })

/* 打款-详情 */
export const fetchReturnPayDetail = (payId: number) => requestFomUrlencoded<IOmsPay>(getUrl("/getOmsPay"), { payId })

/* 供应商退款列表 */
export const fetchSupplierRefundList = (data: IOmsSupplierRefundListDTO & IAjaxPage) => requestPost<IListData<IOmsSupplierRefund>>(getUrl("/listSupplierRefund"), data)

/* 打款流水 */
export const fetchPayFlowList = (data: { afterSaleId: number, payId?: number }) => requestPost<ISysPayTradeFlow[]>(getUrl("/listPayFlow"), data)


const _fetchSupplierExceptionCount = async () => {
    const data = await fetchSupplierRefundList({ pageNum: 1, pageSize: 1, blException: true, state: ESupplierRefundState.SUBMITTED })

    if (!isEmpty(data?.data?.total)) {
        return {
            ...data,
            data: data.data?.total
        }
    }

    return {
        ...data,
        data: 0
    }
}

const _fetchPayExceptionCount = async () => {
    const data = await fetchAfterPayList({ pageNum: 1, pageSize: 1, needProcess: true })

    if (!isEmpty(data?.data?.total)) {
        return {
            ...data,
            data: data.data?.total
        }
    }

    return {
        ...data,
        data: 0
    }
}

export const [fetchSupplierExceptionCount, refreshSupplierExceptionCount, setSupplierExceptionCount] = requestThrottle({
    api: _fetchSupplierExceptionCount,
    times: ONE_MINUTE * 10,
    saveKey: "SupplierExceptionCount",
    session: true
})

export const [fetchPayExceptionCount, refreshPayExceptionCount, setPayExceptionCount] = requestThrottle({
    api: _fetchPayExceptionCount,
    times: ONE_MINUTE * 10,
    saveKey: "PayExceptionCount",
    session: true
})

/* 供应商退款-完成 */
export const finishSupplierRefund = (data: { refundId: number, fileUrlList: string[] }) => requestPost(getUrl("/finishSupplierRefund"), data)