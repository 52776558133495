import { NavigateFunction } from "react-router";
import { ISelectOption } from "../types";
import {compoundRoutePath} from "../utils";

const _data: { [name: string]: any } = {}
const GiftSendLogKey = 'GIFT_SEND_LOG'

export enum EGlobalKey {
    admin = 'adminInfo'
}

export const setGlobalData = <T = any>(key: string | EGlobalKey, data: T): void => {
    _data[key] = data
}

export const getGlobalData = <T = any>(key: string | EGlobalKey, _delete: boolean = false): T => {
    const res = _data[key]
    if (_delete) {
        delete _data[key]
    }
    return res
}


export const getGiftSendLog = (): string[] => {
    let res = _data[GiftSendLogKey]
    if (!res) {
        const l = localStorage.getItem(GiftSendLogKey)
        if (l) {
            const jsonData = JSON.parse(l),
                dateObj = new Date()
            if (jsonData.time < new Date(dateObj.getFullYear(), dateObj.getMonth(), dateObj.getDate()).getTime()) {
                localStorage.removeItem(GiftSendLogKey)
                res = []
            } else {
                res = jsonData.list
            }
        }
    }

    _data[GiftSendLogKey] = res || []
    return res || []
}


export const setGiftSendLog = (log: string[]) => {
    _data[GiftSendLogKey] = log
    const l = localStorage.getItem(GiftSendLogKey)
    if (l) {
        const dateObj = new Date()
        localStorage.setItem(GiftSendLogKey, JSON.stringify({
            list: log,
            time: new Date(dateObj.getFullYear(), dateObj.getMonth(), dateObj.getDate()).getTime()
        }))
    }
}


let navigate: NavigateFunction | null = null

export const setNavigate = (_navigate: NavigateFunction) => {
    navigate = _navigate
}

export const getNavigate = (): NavigateFunction | null => navigate

export const JumpToLogin = () => {
    //window.navigateState({},'login',compoundRoutePath('login'))
    const navigate = getNavigate()
    navigate ? navigate(compoundRoutePath('login')) : (window.location.href = compoundRoutePath('login'))
}


export const TaxOptions: ISelectOption[] = [
	{ value: 0, label: "0%" },
    { value: 1, label: "1%" },
	{ value: 3, label: "3%" },
	{ value: 6, label: "6%" },
	{ value: 9, label: "9%" },
	{ value: 13, label: "13%" },
]

export const SaleTaxOptions: ISelectOption[] = [
	{ value: 0, label: "0%" },
	{ value: 6, label: "6%" },
	{ value: 9, label: "9%" },
	{ value: 13, label: "13%" },
]