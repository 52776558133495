import { Breadcrumb } from "antd"
import { Link, useLocation, useNavigate } from "react-router-dom"
import { generateRoutes } from "../router"
import { compoundRoutePath } from "../utils"
import { ItemType } from "antd/es/breadcrumb/Breadcrumb"

type IPathSearchData = { [name: string]: string }

const pathName = (process.env.PUBLIC_URL || "").slice(1) || ""
let initSearch = false
let searchObj: IPathSearchData = {}

const setSearch = (url: string, search?: string) => {
	url = url.replace(`/${pathName}/`, "/")
	const base =
		url
			.split("/")
			.filter(i => i)
			.shift() || ""
	if (!initSearch) {
		const _search = localStorage.getItem("PATH_SEARCH") || ""
		searchObj = _search ? JSON.parse(_search) : {}
	}
	searchObj = base
		? Object.keys(searchObj)
				.filter(k => k.indexOf(`/${base}`) >= 0)
				.reduce((obj: IPathSearchData, k) => {
					obj[k] = searchObj[k]
					return obj
				}, {})
		: {}

	if (search) {
		searchObj[url] = search
	} else {
		delete searchObj[url]
	}

	localStorage.setItem("PATH_SEARCH", JSON.stringify(searchObj))
}

const getSearch = (url: string) => {
	return searchObj[url] || ""
}

const itemRender = (currentRoute: ItemType) =>
	currentRoute.path ? <Link to={compoundRoutePath(`${currentRoute.path}${getSearch(currentRoute.path)}`)}>{currentRoute.title}</Link> : currentRoute.title

const HomeBreadcrumb = () => {
	const routeList = generateRoutes(true).reduce((obj: { [key: string]: string[] }, item) => {
		obj[item.path.replace(`/${pathName}/`, "/")] = [item.title, item.menuTitle || ""]
		return obj
	}, {})

	let menuTitle = ""
	const location = useLocation()
	const navigate = useNavigate()
	const pathSnippets = location.pathname
		.replace(`/${pathName}/`, "/")
		.split("/")
		.filter(i => i)

	setSearch(location.pathname, location.search)

	const extraBreadcrumbItems = pathSnippets.map((_, index) => {
		const url = `/${pathSnippets.slice(0, index + 1).join("/")}`
		if (!routeList[url]) {
			return null
		}

		menuTitle = routeList[url][1] || ""
		if (index + 1 === pathSnippets.length) {
			document.title = routeList[url][0] || ""
		}

		return {
			title: routeList[url][0],
			path: url,
		}
	})

	if (extraBreadcrumbItems.some(item => !item)) {
		navigate(compoundRoutePath("/404"), { replace: true })
		return null
	}

	extraBreadcrumbItems.unshift({
		title: menuTitle,
		path: "",
	})
    
	return <Breadcrumb itemRender={itemRender} items={extraBreadcrumbItems.filter(Item => !!Item) as { title: string; path: string }[]} style={{ margin: "16px 0" }} />
}

export default HomeBreadcrumb
