import React from "react"
import { Space, Typography } from "antd"
import { css } from "@emotion/react"

interface Props {
	absolute?: boolean
}

const containerStyle = css({ textAlign: "center", width: "100%", paddingBottom: 20 })

const containerPositionStyle = css({
	position: "absolute",
	left: 0,
	bottom: 0,
})

const Footer: React.FC<Props> = ({ absolute }) => {
	
	return (
		<div css={[containerStyle, absolute ? containerPositionStyle : null]}>
			<Space direction={"vertical"}>
				<div>
					<Space size={40}>
						<Typography.Text>帮助</Typography.Text>
						<Typography.Text>隐私</Typography.Text>
						<Typography.Text>条款</Typography.Text>
					</Space>
				</div>

				<div>
					<Typography.Text>
						<span
							css={css`
								font-family: arial;
							`}
						>
							Copyright &copy; 2024
						</span>
						大地电商信息技术支持部出品
					</Typography.Text>
				</div>
			</Space>
		</div>
	)
}

export default React.memo(Footer, (prevProps: Props, nextProps: Props) => prevProps.absolute === nextProps.absolute)
