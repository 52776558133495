import React, { useLayoutEffect, useState } from "react"
import { Button, Checkbox, Form, Input } from "antd"
import { apiResponseCheck, compoundRoutePath } from "../utils"
import { UserOutlined, LockOutlined } from "@ant-design/icons"
import { removeLoading, addLoading } from "../components/Loading"
import { removeToken, setToken } from "../utils/token"
import { setMenus } from "../router"
import Footer from "../components/Footer"
import Particles, { initParticlesEngine } from "@tsparticles/react"
import { loadSlim } from "@tsparticles/slim"
import { NavLink, useNavigate } from "react-router-dom"
import { ISourceOptions } from "@tsparticles/engine"
import { css } from "@emotion/react"
import { login } from "../service/Login"
import { IAdminInfo, fetchLoginAdminInfo } from "../service/Admin"

const Login: React.FC<{ onChange?(detail: IAdminInfo): void }> = ({ onChange }) => {
	const [init, setInit] = useState(false),
		[formElem] = Form.useForm(),
		navigate = useNavigate()

	const options: ISourceOptions = {
		fps_limit: 60,
		interactivity: {
			detectsOn: "canvas",

			modes: {
				push: {
					particles_nb: 4,
				},
				repulse: {
					distance: 200,
					duration: 0.4,
				},
			},
		},
		particles: {
			color: {
				value: "#ddd",
			},
			links: {
				color: "#000",
				distance: 150,
				enable: true,
				opacity: 0.4,
				width: 1,
			},
			move: {
				direction: "none",
				enable: true,
				outModes: {
					default: "out",
				},
				random: false,
				speed: 2,
				straight: false,
			},
			number: {
				density: {
					enable: true,
				},
				value: 50,
			},
			opacity: {
				value: 0.5,
			},
			shape: {
				type: "circle",
			},
			size: {
				value: 8,
			},
		},
		detectRetina: true,
	}

	const goRoute = async () => {
		const detail = await apiResponseCheck(await fetchLoginAdminInfo()),
			routes = detail.menuList.filter((item: { component: any }) => !!item.component)
		onChange?.(detail)
		navigate(compoundRoutePath(routes.length ? routes[0].component : "/"), { replace: true })
	}

	const onLoginClick = async () => {
		try {
			const { name, password } = await formElem.validateFields()
			addLoading()
			removeToken()

			const token = await apiResponseCheck<string>(await login(name, password), {
				errMsg: data => (data.code === 1001 ? "账号不存在或密码错误" : data.message),
			})
			setToken(token)
			setMenus(null)

			goRoute()
		} finally {
			removeLoading()
		}
	}

	const onKeyDown = (e: React.KeyboardEvent) => {
		switch (e.code) {
			case "Enter":
			case "NumpadEnter":
				onLoginClick()
				break
			default:
				break
		}
	}

	useLayoutEffect(() => {
		initParticlesEngine(async engine => {
			await loadSlim(engine)
		}).then(() => setInit(true))
	}, [])

	return (
		<div
			css={css`
				display: flex;
				align-items: center;
				justify-content: center;
				height: 100%;
			`}
		>
			{init && (
				<Particles
					id={"particle"}
					options={options}
					css={css`
						position: absolute;
						left: 0;
						top: 0;
						width: 100%;
						height: 100%;
					`}
				/>
			)}
			<div
				css={css`
					width: 420px;
					background-color: rgba(245, 245, 245, 0.8);
					padding: 50px 70px 100px 70px;
				`}
			>
				<div
					css={css`
						font-size: 24px;
					`}
				>
					新电商宠物商品管理系统
				</div>
				<Form
					form={formElem}
					css={css`
						margin-top: 40px;
					`}
				>
					<Form.Item
						name={"name"}
						rules={[
							{
								required: true,
								message: "请输入用户名",
							},
						]}
					>
						<Input
							prefix={
								<UserOutlined
									css={css`
										color: rgba(0, 0, 0, 0.25);
									`}
								/>
							}
							maxLength={20}
							onKeyDown={onKeyDown}
							placeholder={"用户名"}
							size={"large"}
						/>
					</Form.Item>

					<Form.Item
						name={"password"}
						rules={[
							{
								required: true,
								message: "请输入密码",
							},
						]}
					>
						<Input
							prefix={
								<LockOutlined
									css={css`
										color: rgba(0, 0, 0, 0.25);
									`}
								/>
							}
							type={"password"}
							onKeyDown={onKeyDown}
							maxLength={20}
							placeholder={"密码"}
							size={"large"}
						/>
					</Form.Item>

					<Form.Item>
						<div
							css={css`
								display: flex;
								align-items: center;
								justify-content: space-between;
							`}
						>
							<Form.Item noStyle>
								<Checkbox>记住密码</Checkbox>
							</Form.Item>

							<NavLink to={""}>
								<Button style={{ paddingRight: 0 }} type={"link"}>
									忘记密码
								</Button>
							</NavLink>
						</div>
					</Form.Item>

					<Form.Item>
						<Button
							size={"large"}
							type="primary"
							css={css`
								width: 100%;
							`}
							onClick={onLoginClick}
						>
							登录
						</Button>
					</Form.Item>
				</Form>
			</div>

			<Footer absolute={true} />
		</div>
	)
}

export default Login
