import IDeliverInfoVO from "../conts/IDeliverInfoVO"
import IJkOrderModifyDTO from "../conts/IJkOrderModifyDTO"
import IJkOrderQueryDTO from "../conts/IJkOrderQueryDTO"
import IOmsJkOrder from "../conts/IOmsJkOrder"
import IOmsOrder from "../conts/IOmsOrder"
import IOmsOrderDetailVO from "../conts/IOmsOrderDetailVO"
import IOmsOrderExceptionVO from "../conts/IOmsOrderExceptionVO"
import IOmsOrderListDTO from "../conts/IOmsOrderListDTO"
import { IListData } from "../types"
import { isEmpty, ONE_MINUTE } from "../utils"
import { IAjaxPage, requestDownloadPost, requestFomUrlencoded, requestGet, requestPost, requestThrottle } from "../utils/request"

const getUrl = (url = "") => `/order${url}`

/* 查询订单-(可查询异常，超时) */
export const fetchOrderList = (data: IAjaxPage & IOmsOrderListDTO) => requestPost<IListData<IOmsOrder>>(getUrl("/list"), data)

/* 订单列表导出 */
export const exportOrderList = (data: IOmsOrderListDTO) => requestDownloadPost(getUrl("/exportOrderList"), data)

/* 查询商品详情 */
export const fetchOrderDetail = (orderId: number) => requestGet<IOmsOrderDetailVO>(getUrl("/detail"), { orderId })

/* 查询物流 */
export const fetchOrderDeliverList = (orderId: number) => requestPost<IDeliverInfoVO[]>(getUrl(`/queryDeliver/${orderId}`))

/* 需求订单列表-(可查询异常) */
export const fetchJKOrderList = (data: IAjaxPage & IJkOrderQueryDTO) => requestPost<IListData<IOmsJkOrder>>(getUrl("/listJKOrder"), data)

/* 异常工单编码列表 */
export const fetchExceptionJKOrderList = (data: { exceptionCode?: string } = {}) => requestPost<IOmsOrderExceptionVO[]>(getUrl("/listExceptionJKOrder"), data)

/* 异常需求订单-->待执行 */
export const execExceptionJKOrder = (data: { exceptionCode?: string }) => requestPost<number>(getUrl("/modifyExceptionJKOrderState"), data)

/* 订单取消重推 */
export const reProcessNoDeliverOrder = (orderId: number) => requestFomUrlencoded(getUrl("/reProcessNoDeliverOrder"), { orderId })

/* 需求工单编辑收货信息 */
export const modifyAsJKOrder = (data: IJkOrderModifyDTO) => requestPost(getUrl("/modifyAsJKOrder"), data)

/* 取消订单 */
export const cancelOrder = (data: { orderId: number, note: string }) => requestPost(getUrl("/cancelOrder"), data)

/* 1688重新支付 */
export const rePlayAliOrder = (orderId: number) => requestFomUrlencoded(getUrl("/re1688Pay"), { orderId })


const _fetchOrderExceptionCount = async () => {
    const data = await fetchOrderList({ pageNum: 1, pageSize: 1, blException: true })

    if (!isEmpty(data?.data?.total)) {
        return {
            ...data,
            data: data.data?.total
        }
    }

    return {
        ...data,
        data: 0
    }
}

export const [fetchOrderExceptionCount, refreshOrderExceptionCount, setOrderExceptionCount] = requestThrottle({
    api: _fetchOrderExceptionCount,
    times: ONE_MINUTE * 10,
    saveKey: "OrderExceptionCount",
    session: true
})


const _fetchJKOrderExceptionCount = async () => {
    const data = await fetchExceptionJKOrderList()

    return {
        ...data,
        data: data.data?.length
    }
}

export const [fetchJKOrderExceptionCount, refreshJKOrderExceptionCount, setJKOrderExceptionCount] = requestThrottle({
    api: _fetchJKOrderExceptionCount,
    times: ONE_MINUTE * 10,
    saveKey: "JKOrderExceptionCount",
    session: true
})


export const cancelJKOrder = (data: { jkOrderId: number, note?: string }) => requestPost(getUrl("/cancelJKOrder"), data)