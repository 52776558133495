import { IAjaxPage, requestFomUrlencoded, requestGet, requestPath, requestPost } from "../utils/request"
import { IListData } from "../types"
import { EStatus } from "../components/Constant"
import { IMenuListItem } from "./Menu"

//账号类型:1->管理员;0->非管理员
export enum EAdminType {
	admin = 1,
	normal = 0,
}

/*获取指定用户详情*/
export interface IAdminDetail {
	//账号类型:1->管理员;0->非管理员
	accountType: EAdminType
	adminId: string
	createTime: string
	createUserId: number
	deptList: IAccountDeptItem[]
	email: string
	gender: string
	icon: string
	mobile: string
	nikeName: string
	note: string
	orgId: string
	orgName: string
	password: string
	roleList: IAccountRoleItem[]
	salt: string
	status: EStatus
	updateTime: string
	updateUserId: number
	username: string
	menuList: IMenuListItem[]
}

export interface IAccountDeptItem {
	createTime: string
	createUserId: number
	roleId: string
	name: string
	//组织ID
	orgId: string

	deptId: string
	//上一级部门
	pid: number
	sort: number
	//status	启用状态：0->失效；1->生效
	status: EStatus
	subCount: number
	updateTime: string
	updateUserId: number
}

export interface IAccountRoleItem {
	adminCount: number
	createTime: string
	createUserId: number
	//	数据权限类型:1->本人；2->部门；3->组织
	dataPermissionType: 1 | 2 | 3
	description: string
	name: string
	roleId: string
	sort: number
	status: string
	updateTime: string
	updateUserId: number
}

export const getEmptyAccountDetail = (): IAdminDetail => ({
	accountType: EAdminType.admin,
	adminId: "",
	createTime: "",
	createUserId: 0,
	deptList: [],
	email: "",
	gender: "",
	icon: "",
	mobile: "",
	nikeName: "",
	note: "",
	orgId: "",
	orgName: "",
	password: "",
	roleList: [],
	salt: "",
	status: EStatus.enable,
	updateTime: "",
	updateUserId: 0,
	username: "",
	menuList: [],
})

export const fetchAdminDetail = (adminId: number | string) => requestGet<IAdminDetail>(`/admin/detail/${adminId}`)

/*获取登录用户信息*/
export type IAdminInfo = {
	//账号类型:1->管理员;0->非管理员

	roleList: IAccountRoleItem[]

	username: string
	menuList: IMenuListItem[]
	user: {
		accountType: EAdminType
		adminId: string
		createTime: string
		createUserId: number
		deptList: IAccountDeptItem[]
		email: string
		gender: string
		icon: string
		mobile: string
		nikeName: string
		note: string
		orgId: string
		orgName: string
		password: string
		salt: string
		status: EStatus
		updateTime: string
		updateUserId: number
	}
}

export const fetchLoginAdminInfo = () => requestGet<IAdminInfo>("/admin/info")

/*获取用户列表*/
export interface IAdminListRequest {
	//角色ID
	roleId?: string | ""
	//部门ID
	deptId?: string | ""
	//邮箱
	email?: string
	//排除部门ID
	excludeDeptId?: string
	//排除角色ID
	excludeRoleId?: string
	//手机号
	mobile?: string
	//组织机构名称
	orgId?: string | ""
	//状态
	status?: EStatus | ""
	//账号
	username?: string
}

export const getAdminListRequestEmpty = (): IAdminListRequest => ({
	roleId: "",
	//部门ID
	deptId: "",
	//邮箱
	email: "",
	//手机号
	mobile: "",
	//组织机构名称
	orgId: "",
	//状态
	status: "",
	//账号
	username: "",
})

export interface IAdminListItem {
	accountType: string
	adminId: string
	createTime: string
	createUserId: string
	email: string
	gender: string
	icon: string
	mobile: string
	nikeName: string
	note: string
	orgId: string
	orgName: string
	password: string
	salt: string
	status: EStatus
	updateTime: string
	updateUserId: string
	username: string
}

export const fetchAdminList = (data: IAdminListRequest & IAjaxPage) => requestPost<IListData<IAdminListItem>>("/admin/list", data)

/*修改用户详细信息*/
export interface IEditAdminDetail {
	adminId?: string

	deptIdList?: string[]
	//手机号
	mobile?: string
	//组织ID
	orgId?: string

	roleIdList?: string[]
	//账号
	username?: string
}

export const editAdminDetail = (data: IEditAdminDetail) => requestPost("/admin/modifyUmsAdminDetail", data)

/*用户注册*/
export interface IAdminRegisterData {
	//邮箱
	email?: string
	//手机号
	mobile: string
	//密码
	password?: string
	//账号
	username: string
}

export const registerAdmin = (data: IAdminRegisterData) => requestPost("/admin/register", data)

/*重置用户密码*/
export const resetPassword = (adminId: string, newPassword: string = "123456") =>
	requestPost("/admin/resetPassword", {
		adminId,
		newPassword,
	})

/*给用户分配角色*/
export const updateAdminRole = (adminId: number, roleIds: number[]) =>
	requestPost("/admin/role/update", {
		adminId,
		roleIds,
	})

/*获取指定用户的角色*/
export const fetchRolesOfAdmin = (adminId: number) => requestGet(`/admin/role/${adminId}`)

/*修改登录用户密码*/
export const updateLoginAdminPassword = (data: { oldPassword: string; newPassword: string }) => requestPost("/admin/updatePassword", data)

/*修改帐号状态*/
export const updateAdminStatus = (adminId: string, status: EStatus) =>
	requestPath("/admin/updateStatus", adminId, {
		status,
	})

/*获取指定用户信息*/
export const fetchAdminInfo = (adminId: string) => requestGet<IAdminDetail>(`/admin/${adminId}`)

/*分配用户到角色*/
export const addAdminAssociateRole = (roleId: string, adminIdList: string[]) =>
	requestFomUrlencoded(
		"/role/addAdmin",
		{
			roleId,
			adminIdList,
		},
		true
	)
